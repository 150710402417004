@import '../../vars.scss';

.site-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .enter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .intro-title {
      margin-bottom: 50px;
      & img {
        width: 300px;
        height: auto;
      }
      @include md {
        & img {
          width: 420px;
          height: auto;
        }
      }
    }
    .intro-buttonContainer {
      display: flex;
      justify-content: center;
      
      button {
        background-color: #cfcfcf;
        color: #000000;
        border: #999999;
        padding: 10px 30px;
        font-size: 24px;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 20px 50px;
        &:hover {
          background-color: #aaaaaa;
        }
      }
    }
    .intro-subtext {
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 20px;
      max-width: 80%;
      text-align: center;
      line-height: 18px;

      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }

    .intro-headphones {
      & img {
        width: 100px;
        height: auto;
      }
    }
  }
}

/* Nav Container */
$iconWidth: 120;
$closeWidth: 121;

$sm: 560px;
$md: 1025px;
$lg: 1170px;
$xl: 1320px;

@mixin xs {
  @media (max-width: $sm) {
    @content;
  }
}
@mixin sm {
  @media (min-width: $sm) {
    @content;
  }
}
@mixin md {
  @media (min-width: $md) {
    @content;
  }
}
@mixin lg {
  @media (min-width: $lg) {
    @content;
  }
}
@mixin xl {
  @media (min-width: $xl) {
    @content;
  }
}

.pond-container {
  width: 100vw;
  height: 100vh;
  position: absolute;

  .pond-background {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: top;
    align-items: flex-start;
    overflow: hidden;

    video {
      flex: 1;
      object-fit: cover;
      // width: 100%;
      // height: auto;
    }
  }
  .pond-ripples {
    position: absolute;
    z-index: 1;
    top: 0;
    mix-blend-mode: screen;
  }
}

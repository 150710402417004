.layout {
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  .layout--container {
    position: absolute;
    z-index: 4;
    width: 100vw;
    height: 100vh;
  }
}
.pond-scroll {
  position: absolute;
  z-index: 6;
  width: 152px;
  bottom: 70px;
  left: calc(50% - 76px);
  padding-bottom: 10px;
  opacity: 0;
  cursor: pointer;
  visibility: hidden;

  color: #ffffff;
  font-size: 24px;
  text-align: center;

  img {
    width: 60%;
    height: auto;
    margin-top: 0px;
    transition: all 300ms ease-in-out;

    &:hover {
      margin-top: 10px;
    }
  }
}

.nav-visible {
  opacity: 1;
}
.nav-hidden {
  opacity: 0.2;

}

.transition-container {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  justify-content: top;
  align-items: flex-start;
  display: flex;
  overflow: hidden;

  video {
    margin-left: 50vw;
    margin-top: 50vh;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}
.transition-container-hidden {
  display: none;
}
.transition-container-visible {
  display: flex;
}


@import '../../vars.scss';

.nav-container {
  position: absolute;
  z-index: 12;

  &[data-expanded='false'] {
    &[data-is-video='true'] {
      top: 10px;
      right: 100px;
    }
    &[data-is-video='false'] {
      right: 60px;
      bottom: 60px;
    }

    background-color: transparent;

    width: 60px;
    height: 80px;

    .nav-container-icon {
      display: block;
    }
    .nav-container-close {
      display: none;
    }
  }

  &[data-expanded='true'] {
    background-color: rgba($color: #000000, $alpha: 0.8);
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;

    .nav-container-icon {
      display: none;
    }
    .nav-container-close {
      display: block;
    }
  }
  transition: background-color 0.5s ease;
}

.navTreeContainer {
  position: absolute;
  width: 100vw;
}
.nav-container-close {
  position: absolute;
  z-index: 2;
  right: 60px;
  top: 60px;
  width: 80px;
  height: 80px;

  transition: all 0.5s ease;
  cursor: pointer;

  & img {
    -webkit-filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.8));
    filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.8));
  }

  &:hover {
    -webkit-filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.8));
    filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.8));
  }
}

.nav-container-icon {
  cursor: pointer;
  position: relative;
  & img {
    width: 60px;
    height: auto;

    -webkit-filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.8));
    filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.8));

    transition: all 0.5s ease;
  }

  .nav-container-icon-retract {
    opacity: 1;
    position: absolute;
    z-index: 0;
    top: 0;
  }
  .nav-container-icon-expand {
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
  }

  &:hover {
    -webkit-filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.8));
    filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.8));

    .nav-container-icon-retract {
      opacity: 0;
    }
    .nav-container-icon-expand {
      opacity: 1;
    }
  }
}

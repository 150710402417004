body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

.App {
  position: absolute;
  width: 100vw;
  height: 100vh;

  background-color: #000000;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

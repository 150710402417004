.about-container--wrapper {
  position: absolute;
  z-index: 10;

  &[data-expanded='false'] {
    &[data-is-video='true'] {
      top: 90px;
      right: 80px;
      
    }
    &[data-is-video='false'] {
      bottom: 100px;
      right: 120px;
    }
    .about-container--content {
      display: none;
    }
    .about-container--button {
      display: block;
    }
  }

  &[data-expanded='true'] {
    top: 0;
    left: 0;
    .about-container--content {
      display: block;
    }
    .about-container--button {
      display: none;
    }
  }

  .about-container--button {
    position: relative;
    top: -60px;
    left: 60px;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
  }
  .about-container--content {
    background-color: rgba($color: #000000, $alpha: 0.8);

    width: 100vw;
    height: 100vh;
    .about-container--close {
      position: absolute;
      z-index: 3;
      right: 60px;
      top: 60px;
      width: 80px;
      height: 80px;

      transition: all 0.5s ease;
      cursor: pointer;

      & img {
        -webkit-filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.8));
        filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.8));
      }

      &:hover {
        -webkit-filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.8));
        filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.8));
      }
    }

    .about-container--content-wrap {
      width: 70%;
      margin-left: 15%;
    }
    .about-container--title {
      padding-top: 60px;
    }
    .about-container--sub-title {
      color: #ffffff;
      font-size: 24px;
      margin-bottom: 30px;
    }
    .about--container--copy::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    .about--container--copy::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #707070;
    }

    .about--container--copy::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #2c2c2c;
    }

    .about-container--copy {
      color: #ffffff;
      font-size: 20px;
      overflow: -moz-scrollbars-vertical;
      overflow-y: scroll;
      height: 60vh;
      padding: 20px 0 20px;

      a {
        color: #999999;
        text-decoration: none;

        &:hover {
          color: #ffffff;
          text-decoration: underline;
        }
      }
    }

    .about-container--bio-wrapper {
      display: flex;
      margin: 40px 0 20px;
    }
    .about-container--bio-photo {
      width: 185px;
      img {
        width: 185px;
        height: auto;
      }
    }
    .about-container--bio-text {
      padding: 0 0 0 20px;
    }
  }
}

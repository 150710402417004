@import '../../../vars.scss';

.nav-tree {
  height: 80vh;
  width: 40vw;
  position: absolute;
  right: -60px;
  top: 10vh;
  padding-top: 150px;
}

.nav-tree-item {
  width: 40%;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 37px;

  .nav-tree-item-content {
    padding: 0;
    img {
      margin-left: 5px;
      opacity: 0;
      width: 20px;
      height: 20px;
    }

    &:hover {
      padding-left: 30px;
      img {
        margin-left: 15px;
        opacity: 1;
      }
    }
    transition: all 0.4s ease-out;
  }
}

@import '../../../vars.scss';

.nav-planes {
  height: 80vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -60px;
  top: 5vh;

  .nav-planes-container {
    height: 80vh;
    padding-left: calc(60vw - 570px);
    .nav-planes-item {
      position: relative;
      width: 570px;
      height: auto;
      transition: all 0.3s ease-out;
    }
  }
}

.videoPlayer {
  width: 100vw;
  height: 100vh;
  z-index: 11;
  position: absolute;
  opacity: 0;
}

.videoPlayer--controls {
  width: 100vw;
  height: 100vh;
  z-index: 13;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
    height: auto;
    cursor: pointer;
  }
}

.videoPlayer--cover {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30px;
    height: auto;
  }
}

.videoPlayer--idle {
  animation: fadeIn ease 0.5s;
  animation-fill-mode: forwards;
}
.videoPlayer--fadeout {
  animation: fadeOut ease 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.videoPlayer--container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    position: static !important;
  }
}
